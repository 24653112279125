import { useRouter } from "next/router";

export const useLiveChat = () => {
    const { locale, locales } = useRouter();

    const currentIndex = locales.indexOf(locale);

    const buttonIds = process.env.NEXT_PUBLIC_LIVE_CHAT_BUTTON_IDS?.split(",");

    const buttonId = buttonIds[currentIndex] ?? buttonIds[0];

    const deploymentIds = process.env.NEXT_PUBLIC_LIVE_CHAT_DEPLOYMENT_IDS?.split(",");

    const deploymentId = deploymentIds[currentIndex] ?? deploymentIds[0];

    const liveChatIds = process.env.NEXT_PUBLIC_LIVE_CHAT_IDS?.split(",");

    const liveChatId = liveChatIds[currentIndex] ?? liveChatIds[0];

    const env = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === "master" ? "prod" : "test";

    const serviceCloudApiPrefix = env === "prod" ? "eccosc" : "eccosc--qa.sandbox";
    const baseLiveAgentURLPrefix = env === "prod" ? "la1" : "la2s";

    return {
        buttonId,
        deploymentId,
        liveChatId,
        serviceCloudApiUrl: `https://${serviceCloudApiPrefix}.my.salesforce.com`,
        serviceCloudChatApiUrl: `https://${serviceCloudApiPrefix}.my.salesforce-sites.com/Chat`,
        baseLiveAgentURL: `https://d.${baseLiveAgentURLPrefix}-core1.sfdc-cehfhs.salesforceliveagent.com/chat`,
        baseLiveAgentContentURL: `https://d.${baseLiveAgentURLPrefix}-core1.sfdc-cehfhs.salesforceliveagent.com/content`,
    };
};
